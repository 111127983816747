<template>
  <div class="container">
    <div class="row">
      <div class="col-3"></div>
      <div class="col-lg-6 col-md-8 col-sm-12">
        <h3>Verify your email address</h3>
        <hr />
        <p>
          Thank you for creating an account wih us. We've just sent a
          verification code to your email. OTP will expire in 1 hour.
        </p>
        <div></div>
      </div>
      <div class="col-3"></div>
    </div>
    <div class="row" id="otp-inp">
      <v-otp-input
        length="5"
        type="number"
        v-model="authcode"
        @change="verifycode"
      >
      </v-otp-input>
    </div>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-lg-6 col-md-8 col-sm-12">
        <p>
          Didn't receive the confirmation code? Check <b>SPAM</b> folder in your
          mailbox.
        </p>
        <div></div>
      </div>
      <div class="col-3"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    endDate: {
      // pass date object till when you want to run the timer
      type: Date,
      default() {
        return new Date();
      },
    },
    negative: {
      // optional, should countdown after 0 to negative
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      authcode: 0,
    };
  },
  methods: {
    verifycode() {
      if (this.authcode.length == 5) {
        ApiService.post("verifycode", {
          id: this.currentUser.id,
          code: this.authcode,
        })
          .then((response) => {
              if(response.data.success){
            this.$router.push({ name: "dashboard" });
              }else{
                  alert('Token is not valid');
              }
           
          })
          .catch(() => {});
      }
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<style scoped>
body {
  background-color: white !important;
}

#otp-inp {
  margin-left: 30%;
  width: 40%;
}

.row {
  margin-top: 10vh;
}

.row h3 {
  font-weight: 600;
  font-size: 25px;
  padding: 20px;
}

.row p {
  font-size: 20px;
}

h1 {
  color: red;
}
</style>
